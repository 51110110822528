<script setup>
import { inject, computed } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const selectedTab = inject("selectedTab");
const tabsLoaded = inject("tabsLoaded");

const isLoaded = computed(() => tabsLoaded.value.includes(props.value));
const isShow = computed(() => selectedTab.value === props.value);
</script>

<template>
  <div class="content-tab" v-if="isLoaded" v-show="isShow">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.content-tab {
  animation: content-tab 0.3s both;
}

@keyframes content-tab {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
