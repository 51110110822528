<script setup>
import { ContentDescription, ContentTitle } from "../../base-components";

import iconCurrentAccount from "@/assets/img/icons/statements/current-account.svg";
import iconSavingsAccount from "@/assets/img/icons/statements/savings-account.svg";

const emit = defineEmits(["next"]);

const options = [
  {
    title: "Conta corrente",
    description: "Ideal para transações diárias, pagamentos, e movimentações frequentes.",
    icon: iconCurrentAccount,
  },
  {
    title: "Conta poupança",
    description: "Focada em economizar, com rendimentos e menos movimentações.",
    icon: iconSavingsAccount,
  },
];

function selectOption() {
  emit("next");
}
</script>

<template>
  <div>
    <ContentTitle> Qual a modalidade da sua conta ? </ContentTitle>

    <ContentDescription class="desc">
      Escolha a modalidade correta da conta, pois isso é crucial para o depósito.
    </ContentDescription>

    <div v-for="(option, index) in options" :key="index" @click="selectOption" class="card">
      <section>
        <img :src="option.icon" :alt="option.title" />
        <span>{{ option.title }}</span>
      </section>

      <p>{{ option.description }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.desc {
  margin-bottom: 30px;
}

.card {
  width: 100%;
  height: 145px;

  display: grid;
  gap: 5px;
  padding: 20px;

  margin-top: 20px;

  border-radius: 10px;
  background-color: var(--gray-10);
  border: none;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    transform: scale(0.995);
  }

  section {
    display: flex;
    align-items: center;
    gap: 15px;

    span {
      font-size: 16px;
      font-weight: 700;
      color: var(--dark-500);
    }
  }

  p {
    font-weight: 400;
    color: var(--gray-200);
    line-height: 25px;
    max-width: 80%;
  }
}
</style>
