import { render, staticRenderFns } from "./StepSelectBank.vue?vue&type=template&id=64c6a557&scoped=true"
import script from "./StepSelectBank.vue?vue&type=script&setup=true&lang=js"
export * from "./StepSelectBank.vue?vue&type=script&setup=true&lang=js"
import style0 from "./StepSelectBank.vue?vue&type=style&index=0&id=64c6a557&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c6a557",
  null
  
)

export default component.exports