<script setup>
import {
  GrModal,
  GrModalContent,
  GrModalHeader,
  GrModalFooter,
  GrModalClose,
} from "@/components/GrComponents/GrModal";
import { GrButton } from "@/components/GrComponents/button";
import { Content, ContentDescription, ContentTitle } from "../base-components";
import { useModal } from "@/composables/useModal";

const { getModalState } = useModal();

const modalName = "modal-delete-bank";
const modalState = getModalState(modalName);
</script>

<template>
  <GrModal :model-value.sync="modalState">
    <GrModalContent size="sm">
      <GrModalHeader />

      <Content>
        <span class="icon">
          <img src="@/assets/img/icons/statements/bank-account-red.svg" />
        </span>

        <ContentTitle> Você está excluindo uma conta bancária </ContentTitle>

        <ContentDescription>
          Se você prosseguir com esta ação, a exclusão da conta será <br />
          definitiva e irreversível. Além disso, qualquer solicitação de <br />
          saque associada a esta conta será cancelada.
        </ContentDescription>

        <section class="bank-account">
          <section>
            <h2>ITAÚ</h2>
            <h3>ITAÚ UNIBANCO</h3>
          </section>

          <p>
            Ag: 7212 <br />
            CC: 2575458
          </p>
        </section>
      </Content>

      <GrModalFooter align="center" class="footer">
        <GrModalClose>
          <GrButton variant="gray-white"> Manter conta </GrButton>
        </GrModalClose>

        <GrButton variant="red" class="delete"> Excluir conta </GrButton>
      </GrModalFooter>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped>
.icon {
  background-color: var(--red-50);
  width: var(--w-16);
  height: var(--h-16);
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;

  border-radius: var(--radius-full);
}

.footer {
  button,
  div {
    width: 210px !important;
  }

  .delete {
    background-color: var(--red-50);
  }
}

.bank-account {
  width: 90%;
  margin: auto;
  height: 82px;
  border-radius: 10px;

  background-color: var(--gray-10);

  display: flex !important;
  align-items: center;
  gap: 80px !important;
  padding: 0 14px;

  div {
    width: min-content;

    text-wrap: nowrap;
  }
  h2 {
    color: var(--yellow-500);
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 8px;
  }

  h3 {
    color: var(--dark-500);
    font-size: 12px;
    font-weight: 600;
  }

  p {
    color: var(--gray-200);
    font-size: 13px;
    font-weight: 400;
    line-height: 20.8px;
  }
}
</style>
