<script setup>
import { ref, computed } from "vue";
import { ContentDescription, ContentTitle, ContentButtonNextStep } from "../../base-components";

const emit = defineEmits(["next"]);

const search = ref("");
const selectedBank = ref(null);

const banks = ref([]);

const filteredBanks = computed(() => {
  if (!search.value) return [];
  return banks.value.filter((bank) => bank.name.toLowerCase().includes(search.value.toLowerCase()));
});

function selectBank(bank) {
  selectedBank.value = bank;
}
</script>

<template>
  <section>
    <ContentTitle>
      Qual é o banco responsável por essa <br />
      conta bancária?
    </ContentTitle>

    <ContentDescription> Digite o nome no campo abaixo para pesquisa </ContentDescription>

    <input v-model="search" class="input-balance" />

    <div v-if="filteredBanks.length" class="bank-options">
      <div
        v-for="(bank, index) in filteredBanks"
        :key="index"
        class="bank-option"
        :style="{
          backgroundColor: selectedBank?.name === bank.name ? `${bank.color}20` : '#f9f9f9',
        }"
        @click="selectBank(bank)"
      >
        <h4 :style="{ color: bank.color }">{{ bank.name }}</h4>
        <p>{{ bank.description }}</p>
        <p>Código: {{ bank.code }}</p>
      </div>
    </div>

    <ContentButtonNextStep :disabled="!selectedBank" @click="emit('next')">
      Continuar
    </ContentButtonNextStep>
  </section>
</template>

<style lang="scss" scoped>
.input-balance {
  height: var(--h-12);
  width: 225px;
  margin: 0 auto 25px auto !important;

  padding: var(--spacing-2-5);

  transition: all 0.2s ease !important;

  cursor: pointer;
  outline: none;

  color: var(--dark-500) !important;
  font-size: 28px !important;
  font-weight: var(--weight-extrabold);
  text-align: center;

  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid var(--gray-50) !important;

  display: flex;

  &:focus-visible,
  &:hover {
    outline: none !important;
    border-color: var(--gray-100) !important;
  }
}

.bank-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px auto;
  max-width: 300px;
}

.bank-option {
  background-color: var(--gray-10);
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--gray-10);
  }

  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
  }

  p {
    margin: 5px 0 0;
    font-size: 14px;
    color: var(--old-800);
  }
}
</style>
