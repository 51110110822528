<script setup>
import { ContentDescription, ContentTitle } from "../../base-components";

import iconFirstInstallments from "@/assets/img/icons/statements/first-installments.svg";
import iconLastInstallments from "@/assets/img/icons/statements/last-installments.svg";
import { useI18n } from "@/utils/useI18n";
import { useStore } from "@/utils/useStore";
import { computed } from "vue";

const i18n = useI18n();
const { dispatch, getters } = useStore();

const emit = defineEmits(["next"]);

const getRequestValueAnticipate = computed(() => getters["anticipation/getRequestValueAnticipate"]);

const options = [
  {
    title: i18n.t("anticipation.steps.select-installments.first.title"),
    description: i18n.t("anticipation.steps.select-installments.first.desc"),
    type: "first",
    icon: iconFirstInstallments,
  },
  {
    title: i18n.t("anticipation.steps.select-installments.last.title"),
    description: i18n.t("anticipation.steps.select-installments.last.desc"),
    type: "last",
    icon: iconLastInstallments,
  },
];

async function selectType(type) {
  console.log(getRequestValueAnticipate.value);
  const data = {
    amount: getRequestValueAnticipate.value,
    getAmountBy: type,
  };

  await dispatch("anticipation/fetchSelectInstallments", data);
  emit("next", "options-invoices");
}
</script>

<template>
  <div>
    <ContentTitle> {{ $t("anticipation.steps.select-installments.title") }} </ContentTitle>

    <ContentDescription class="desc">
      {{ $t("anticipation.steps.select-installments.desc") }}
    </ContentDescription>

    <div
      v-for="(option, index) in options"
      :key="index"
      :class="['card', { 'card-installments': option.type === 'first' }]"
      @click="selectType(option.type)"
    >
      <section>
        <img :src="option.icon" :alt="option.title" />
        <span>{{ option.title }}</span>
      </section>

      <p>{{ option.description }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.desc {
  margin-bottom: 30px;
}

.card-installments {
  background-color: var(--blue-50) !important;

  span,
  p {
    color: var(--blue-500) !important;
  }
}

.card {
  width: 100%;
  height: 145px;

  display: grid;
  gap: 10px;
  padding: 20px;

  margin-top: 20px;

  border-radius: 10px;
  background-color: var(--gray-10);
  border: none;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    transform: scale(0.995);
  }

  section {
    display: flex;
    align-items: center;
    gap: 15px;

    span {
      font-size: 16px;
      font-weight: 700;
      color: var(--dark-500);
    }
  }

  p {
    font-weight: 400;
    color: var(--gray-200);
    line-height: 25px;
    max-width: 80%;
  }
}
</style>
