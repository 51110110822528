<script setup>
import { ContentDescription, ContentTitle } from "../../base-components";
import IconSuccess from "@/components/shared/IconSuccess.vue";
import IconError from "@/components/shared/IconError.vue";
import { GrButton } from "@/components/GrComponents";
import { useModal } from "@/composables/useModal";

const emits = defineEmits(["close"]);

const { closeModal } = useModal();

const props = defineProps({
  status: {
    default: "success",
    required: true,
    type: String,
    validator: (value) => ["error", "success"].includes(value),
  },
});

function close() {
  emits("close");
  closeModal("modal-anticipation");
}
</script>

<template>
  <section class="content">
    <IconSuccess v-if="status === 'success'" />
    <IconError v-if="status === 'error'" />

    <ContentTitle class="title">
      {{
        status === "success"
          ? $t("anticipation.steps.status-anticipation.success.title")
          : $t("anticipation.steps.status-anticipation.error.title")
      }}
    </ContentTitle>

    <ContentDescription class="description">
      <template v-if="status === 'success'">
        {{ $t("anticipation.steps.status-anticipation.success.desc") }}
      </template>

      <template v-else>
        {{ $t("anticipation.steps.status-anticipation.error.desc") }}
      </template>
    </ContentDescription>

    <GrButton v-if="status === 'success'" class="return-extract" @click="close">
      <img src="@/assets/img/icons/statements/coins.svg" alt="Coins" />
      {{ $t("anticipation.steps.status-anticipation.extract-account") }}
    </GrButton>
  </section>
</template>

<style scoped lang="scss">
.content {
  display: grid;
  place-items: center;

  .title {
    margin-top: 48px;
  }

  .description {
    margin-bottom: 60px !important;
  }

  .return-extract {
    background-color: var(--primary-50);
    color: var(--primary-600);
    width: 315px;
  }
}
</style>
